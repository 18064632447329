<template>
    <div id="app">
        <a-modal v-model="show" title="部门信息" :width="600" @ok="doSubmit" @cancel="_close" centered>
            <a-form-model ref="form" :model="formData" :rules="formRule">
                <a-row :gutter="15">
                    <a-col span="12">
                        <a-form-model-item label="部门名称" label-position="top" prop="name">
                            <a-input v-model="formData.name" placeholder="请输入部门名称"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col span="12" v-if="getOrganId() == 0">
                        <a-form-model-item label="所属单位" label-position="top" prop="organId">
                            <a-select v-model="formData.organId" style="width:100%"
                                @change="() => { formData.managerIds = []; }">
                                <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
                                    organ.name }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="15">
                    <a-col span="12">
                        <a-form-model-item label="上级部门" label-position="top" prop="parentId">
                            <a-cascader :options="depList" v-model="parentIds"
                                :fieldNames="{ label: 'name', value: 'depId', children: 'children' }" changeOnSelect
                                @change="setParentId" :allowClear="false"></a-cascader>
                        </a-form-model-item>
                    </a-col>
                    <a-col span="8">
                        <a-form-model-item label="电话" label-position="top" prop="tel">
                            <a-input v-model="formData.tel" placeholder="请输入单位电话"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col span="4">
                        <a-form-model-item label="排序" label-position="top" prop="sort">
                            <a-input-number v-model="formData.sort"></a-input-number>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="15">
                    <a-col span="24">
                        <a-form-model-item label="部门主管" label-position="top" prop="managerIds">
                            <select-staff ref="staff" v-model="formData.managerIds" :names="formData.managers"
                                :organId="formData.organId" multiple></select-staff>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="15">
                    <a-col span="24">
                        <a-form-model-item label="职能描述" label-position="top" prop="description">
                            <a-input type="textarea" v-model="formData.description" placeholder="职能描述信息"></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>

            </a-form-model>
            <a-spin size="large" fix v-if="loading" />
        </a-modal>

    </div>
</template>

<script>
import request, { apiUrl, editorUpload } from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
export default {
    data() {
        return {
            show: false,
            loading: false,
            depList: [],
            parentIds: [],
            organList: apiUtil.getOrganList(),
            styles: {
                height: 'calc(100% - 55px)',
                overflow: 'auto',
                paddingBottom: '53px',
                position: 'static'
            },
            formData: {
                depId: '',
                parentId: 0,
                organId: '',
                name: '',
                tel: '',
                managers: '',
                managerIds: [],//部门主管
                description: '',
                sort: 0
            },
            formRule: {
                name: [{ required: true, message: '请输入单位名称' }],
                organId: [{ required: true, message: '请选择所属单位' }],
                parentId: [{ required: true, message: '请选择上级部门' }],
            }
        }
    },
    mounted() {
    },
    props: {
        list: {
            type: Array,
            default: () => { return []; }
        }
    },
    watch: {
        list(val) {
            let first = [{ name: '无', depId: 0, children: [] }];
            let merge = first.concat(val);
            return this.depList = merge;
        }
    },
    methods: {
        ...apiUtil,
        setParentId(value) {
            let len = value.length;
            this.formData.parentId = value[len - 1];
        },
        editorUpload,
        _show(form) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                if (!form) {
                    this.formData.depId = '';
                } else {
                    this.formData = JSON.parse(JSON.stringify(form));
                }
                this.getParentIds(this.formData.parentId);
            })

        },
        _close() {
            this.show = false;
            this.$emit('close')
        },
        // 添加子部门
        addSubDep(pid) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.formData.depId = '';
                this.formData.organId = '';
                this.formData.parentId = pid;
                this.getParentIds(this.formData.parentId);
            })
        },
        doSubmit() {
            if (this.formData.depId > 0 && this.formData.parentId == this.formData.depId) {
                this.parentIds = [];
                utils.error('选择的上级部门无效');
                return;
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    utils.showSpin();
                    request.post('/platform/depart/save', this.formData).then(ret => {
                        // console.log(ret)
                        this.show = false;
                        if (ret.code == 200) {
                            utils.success('操作成功！').then(() => {
                                this.$refs.form.resetFields();
                                this.show = false;
                                this.$emit('success');
                            })
                        }
                    })
                }
            })
        },
        getParentIds(pid) {
            function findMenuIds(list, pid) {
                let ids = [];
                let obj = findMenuId(list, pid);
                ids.push(obj.depId);
                while (obj.parentId > 0) {
                    obj = findMenuId(list, obj.parentId)
                    ids.push(obj.depId);
                }
                return ids;
            }
            function findMenuId(list, pid) {
                let res = null;
                for (let i = 0; i < list.length; i++) {
                    let x = list[i];
                    if (x.depId == pid) {
                        res = x;
                        break;
                    } else {
                        if (x.children && x.children.length > 0) {
                            res = findMenuId(x.children, pid);
                            if (res != null) {
                                break;
                            }
                        }
                    }
                }
                return res;
            }
            this.parentIds = findMenuIds(this.depList, pid).reverse();
        },
    }
}
</script>

<style scoped>
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
</style>
