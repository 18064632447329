<template>
	<div id="ap">
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<common-page-btn @save="$refs.departForm._show()"></common-page-btn>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="12">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList"
										@on-clear="getList" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="6" v-if="getOrganId() == 0">
									<a-select v-model="param.organId" style="width:100%" @change="doRefresh()"
										placeholder="根据单位筛选">
										<a-select-option value="">所有单位</a-select-option>
										<a-select-option :value="organ.organId" v-for="(organ, index) in organList"
											:key="index">{{ organ.name }}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-layout>
			<a-layout-content>
				<a-table :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
					:style="{ background: '#FFF' }" size="small" row-key="depId" border :columns="keys" ref="list"
					:loading="loading" :data-source="data">
					<!--主管-->
					<template slot-scope="row" slot="manager">
						<template v-if="row.managers.length > 0">
							{{ row.managers.join("|") }}
						</template>
						<template v-else>-</template>
					</template>
					<!--操作-->
					<template slot-scope="row" slot="action">
						<a-button-group shape="circle" size="small">
							<a-tooltip title="添加子部门"><a-button type="link" v-has="'depart:save'" icon="plus"
									@click="$refs.departForm.addSubDep(row.depId)"></a-button></a-tooltip>
							<a-tooltip title="编辑"><a-button type="link" v-has="'depart:save'" icon="edit"
									@click="$refs.departForm._show(row)"></a-button></a-tooltip>
							<a-tooltip title="删除" v-if="row.organId > 0"><a-button type="link" v-has="'depart:del'"
									icon="delete" @click="del(row.depId)"></a-button></a-tooltip>
						</a-button-group>
					</template>
				</a-table>
			</a-layout-content>
		</a-layout>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>
		<a-back-top />

		<departForm ref="departForm" @success="getList" :list="data"></departForm>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil'
import utils from '@/common/utils';
import departForm from './components/departFrom.vue'
export default {
	components: { departForm },
	data() {
		return {
			loading: false,
			data: [],
			organList: apiUtil.getOrganList(),
			param: {
				page: 1,
				limit: 20,
				organId: apiUtil.getOrganId(''),
				keyword: ''
			},
			keys: [
				// { title: '#',  scopedSlots: { customRender: 'number' }, width: 60, align: 'center', fixed: 'left' },
				// { title: 'ID', dataIndex: 'depId', width: 100, align: 'center', fixed: 'left' },
				{ title: '部门名称', dataIndex: 'name', align: 'left', ellipsis: true },
				{ title: '部门主管', scopedSlots: { customRender: 'manager' }, align: 'left', ellipsis: true, ellipsis: true },
				{ title: '所属单位', dataIndex: 'organName', width: 260, ellipsis: true },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			]
		};
	},
	created() {
		this.getList();
	},
	mounted() {
	},
	methods: {
		...apiUtil,
		//删除
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/depart/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
				});
			});
		},
		doRefresh() {
			this.param.page = 1;
			this.getList();
		},
		// 获取数据
		getList() {
			this.utils.loading();
			this.loading = true;
			this.http
				.get('/platform/depart/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	}
};
</script>

<style scoped></style>
